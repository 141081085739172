<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                    <b-row>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="Crop Name" vid="crop_name_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="3"
                              label-for="crop_name_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('germConfig.cropName') }}<span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              v-model="descriptorHeading.crop_name_id"
                              :options="CropNameList"
                              id="crop_name_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                       <b-col lg="4" sm="12">
                         <ValidationProvider name="Descriptor Label" vid="descriptor_label_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="3"
                              label-for="descriptor_label_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('germConfig.descriptor_label') }}<span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              v-model="descriptorHeading.descriptor_label_id"
                              :options="descriptorLabelList"
                              id="descriptor_label_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                         <ValidationProvider name="Descriptor Heading" vid="descriptor_heading_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="3"
                              label-for="descriptor_heading_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('germConfig.descriptor_heading') }}<span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              v-model="descriptorHeading.descriptor_heading_id"
                              :options="descriptorHeadingList"
                              id="descriptor_heading_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <hr>
                        <b-row v-for="(detail,index) in descriptorHeading.details" :key="index">
                          <b-col xl="2" lg="2" sm="8">
                              <ValidationProvider name="Type" vid="type_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="type_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('germConfig.type') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="detail.type_id"
                                    :options="typeList"
                                    id="type_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                          </b-col>
                            <b-col xl="2" lg="2" sm="8">
                                <ValidationProvider name="Code Guide Serial" vid='code_guide_sl' rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            label-for="code_guide_sl"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{ $t('germConfig.code_guide_serial')}}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            type="number"
                                            id="code_guide_sl"
                                            v-model="detail.code_guide_sl"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col xl="3" lg="2" sm="8">
                                <ValidationProvider name="Code Guide Name (En)" vid='code_guide_name' rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            label-for="code_guide_name"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{ $t('germConfig.code_guide_name_en')}}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            id="code_guide_name"
                                            v-model="detail.code_guide_name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                            </b-col>
                            <b-col xl="3" lg="2" sm="8">
                                <ValidationProvider name="Code Guide Name (Bn)" vid='code_guide_name_bn' rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            label-for="code_guide_name_bn"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{ $t('germConfig.code_guide_name_bn')}}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            id="code_guide_name_bn"
                                            v-model="detail.code_guide_name_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                            </b-col>
                            <b-col xl="1" lg="1" sm="4" class="mt-4">
                                <b-button v-show="index == descriptorHeading.details.length-1" variant=" iq-bg-success" size="sm" @click="add()"><i class="ri-add-line m-0"></i></b-button>
                                <b-button v-show="index || ( !index && descriptorHeading.details.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                            </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { codeGuideStore, codeGuideUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getDescriptorHeadingData()
      this.descriptorHeading = tmp
             const descriptorData = tmp.details.map(item => {
                return {
                  code_guide_sl: item.code_guide_sl,
                  code_guide_name: item.code_guide_name,
                  code_guide_name_bn: item.code_guide_name_bn,
                  type_id: item.type_id
                }
            })
            this.descriptorHeading.details = descriptorData
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      descriptorHeading: {
        crop_name_id: 0,
        descriptor_label_id: 0,
        descriptor_heading_id: 0,
        details: [
            {
                type_id: 0,
                code_guide_sl: '',
                code_guide_name: '',
                code_guide_name_bn: ''
            }
        ]
      },
      detail: [
            {
                type_id: 0,
                code_guide_sl: '',
                code_guide_name: '',
                code_guide_name_bn: ''
            }
      ],
      descriptorLabelList: [],
      typeList: [
        { value: 1, text: 'MCQ' },
        { value: 2, text: 'Blank' }
      ],
      descriptorHeadingList: []
    }
  },
  computed: {
    CropNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
    }
  },
  watch: {
        'descriptorHeading.crop_name_id': function (newVal, oldVal) {
         this.descriptorLabelList = this.getDescriptorLabelList(newVal)
        },
        // 'detail.type_id': function (newVal, oldVal) {
        //  this.descriptorHeadingList = this.getDescriptorHeading(newVal)
        // },
        'descriptorHeading.descriptor_label_id': function (newVal, oldVal) {
         this.descriptorHeadingList = this.getDescriptorHeading(newVal)
        }
    },
  methods: {
    getDescriptorLabelList (cropNameId = null) {
      const descriptorLabelList = this.$store.state.SeedsFertilizer.commonObj.descriptorLabelList.filter(item => item.status === 1)
      if (cropNameId) {
        return descriptorLabelList.filter(label => label.crop_name_id === cropNameId)
      }
      return descriptorLabelList
    },
    getDescriptorHeading (labelID = null) {
      const descriptorLabelList = this.$store.state.SeedsFertilizer.commonObj.descriptorHeadingList
      if (labelID) {
        return descriptorLabelList.filter(label => label.descriptor_label_id === labelID)
      }
      return descriptorLabelList
    },
    async saveData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.id) {
          result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${codeGuideUpdate}/${this.id}`, this.descriptorHeading)
      } else {
          result = await RestApi.postData(seedFertilizerServiceBaseUrl, codeGuideStore, this.descriptorHeading)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
          this.$store.commit('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
          this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
          })

          this.$bvModal.hide('modal-4')
      } else {
          this.$refs.form.setErrors(result.errors)
      }
    },
     getDescriptorHeadingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    add () {
      const tampbpSetup = {
          type_id: 0,
          code_guide_sl: '',
          code_guide_name: '',
          code_guide_name_bn: ''
      }
      const key1 = parseInt(this.descriptorHeading.details.length - 1)
      const item = this.descriptorHeading.details[key1]
      let isEmpty = true
      Object.keys(item).map(key => {
          if (item[key] === '' || item[key] === 0) {
              isEmpty = false
          }
      })
      if (isEmpty === true) {
          this.descriptorHeading.details.push(tampbpSetup)
      }
    },
    remove (key) {
        this.descriptorHeading.details.splice(key, 1)
    }
  }
}
</script>
