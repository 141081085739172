
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('germConfig.code_guide') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                  <b-col>
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('germConfig.cropName')"
                    label-for="email"
                    >
                    <v-select name="croptypeID"
                    v-model="search.crop_name_id"
                    label="text"
                    :options= CropNameList
                    />
                    </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('germConfig.code_guide_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" :emptyText="$t('globalTrans.noDataFound')" show-empty striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                          <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                          <b-button :variant="data.item.status === 1 ? ' iq-bg-success' : '  iq-bg-danger'" size="sm" @click="remove(data.item)">
                                              <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                          </b-button> -->
                                          <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                              <i class="ri-ball-pen-fill"></i>
                                          </a>
                                          <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                                              <i class="fas fa-toggle-on"></i>
                                          </a>
                                          <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 2">
                                              <i class="fa fa-toggle-off"></i>
                                          </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { codeGuideList, codeGuideToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
      search: {
        crop_name_id: 0
      },
      manage_campaign_event: {
          details: [
              {
                  division_id: '0',
                  district_id: '0'
              }
          ]
      },
      rows: [],
      item: ''
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
    CropNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('germConfig.code_guide') + ' ' + this.$t('globalTrans.entry') : this.$t('germConfig.code_guide') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('germConfig.cropName'), class: 'text-center' },
          { label: this.$t('germConfig.descriptor_label'), class: 'text-center' },
          { label: this.$t('germConfig.descriptor_heading'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'crop_name_bn' },
          { key: 'descriptor_label_bn' },
          { key: 'descriptor_header_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'crop_name' },
          { key: 'descriptor_label' },
          { key: 'descriptor_header' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
    },
    remove (item) {
      this.changeStatus(seedFertilizerServiceBaseUrl, codeGuideToggleStatus, item, 'incentive_grant')
    },
    loadData () {
        var cropNameIdSerach = ''
        if (this.search.crop_name_id) {
            cropNameIdSerach = this.search.crop_name_id.value
        }
      const params = Object.assign({}, this.search, { crop_name_id: cropNameIdSerach, page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(seedFertilizerServiceBaseUrl, codeGuideList, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
     getCustomDataList (data) {
          const listData = data.map(item => {
            const cropNameObj = this.$store.state.SeedsFertilizer.commonObj.CropNameList.find(doc => doc.value === parseInt(item.crop_name_id))
            const descriptorLevelObj = this.$store.state.SeedsFertilizer.commonObj.descriptorLabelList.find(doc => doc.value === parseInt(item.descriptor_label_id))
             const descriptorHeadingObj = this.$store.state.SeedsFertilizer.commonObj.descriptorHeadingList.find(doc => doc.value === parseInt(item.descriptor_heading_id))
            const cropNameData = {}
            const descriptorData = {}
            if (typeof cropNameObj !== 'undefined' && typeof descriptorLevelObj !== 'undefined' && typeof descriptorHeadingObj !== 'undefined') {
              cropNameData.crop_name = cropNameObj.text_en
              cropNameData.crop_name_bn = cropNameObj.text_bn
              descriptorData.descriptor_label = descriptorLevelObj.text_en
              descriptorData.descriptor_label_bn = descriptorLevelObj.text_bn
              descriptorData.descriptor_header = descriptorHeadingObj.text_en
              descriptorData.descriptor_header_bn = descriptorHeadingObj.text_bn
            } else {
              cropNameData.crop_name = ''
              cropNameData.crop_name_bn = ''
              descriptorData.descriptor_label = ''
              descriptorData.descriptor_label_bn = ''
              descriptorData.descriptor_header = ''
              descriptorData.descriptor_header_bn = ''
            }

            return Object.assign({}, item, cropNameData, descriptorData)
          })
          return listData
      }
  }
}
</script>
